import React, { Component } from 'react'
import PropTypes from 'prop-types'
import smoothscroll from 'smoothscroll-polyfill'

/**
 * Anchor scroll link
 *
 * @link https://github.com/mauricevancooten/react-anchor-link-smooth-scroll
 */

class AnchorLink extends Component {
  constructor(props) {
    super(props)
    this.smoothScroll = this.smoothScroll.bind(this)
  }

  componentDidMount() {
    smoothscroll.polyfill()
  }

  smoothScroll(e) {
    if (window) {
      e.preventDefault()
      let offsetCalc = () => 0
      const { offset, onClick, clearHash, delay } = this.props

      if (typeof offset !== 'undefined') {
        if (offset && offset.constructor && offset.apply) {
          offsetCalc = offset
        } else {
          offsetCalc = () => parseInt(offset, 10)
        }
      }
      const id = e.currentTarget.getAttribute('href').slice(1)
      const $anchor = document.getElementById(id)
      if ($anchor) {
        if (typeof onClick === 'function') {
          onClick(e)
        }
        setTimeout(() => {
          const offsetTop =
            $anchor.getBoundingClientRect().top + window.pageYOffset

          if (!clearHash) {
            if ('pushState' in window.history) {
              window.history.pushState(null, null, `#${id}`)
            }
          }

          window.scroll({
            top: offsetTop - offsetCalc(),
            behavior: 'smooth',
          })
        }, delay)
      } else {
        console.warn(`Error: Couldnt find #${id}`) // eslint-disable-line no-console
      }
    }
  }

  render() {
    const { offset, clearHash, ...rest } = this.props
    /* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    return <a {...rest} onClick={this.smoothScroll} />
    /* eslint-enable */
  }
}

AnchorLink.defaultProps = {
  className: undefined,
  offset: undefined,
  onClick: () => {},
  clearHash: true,
  delay: 0,
}

AnchorLink.propTypes = {
  className: PropTypes.string,
  offset: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  onClick: PropTypes.func,
  clearHash: PropTypes.bool,
  delay: PropTypes.number,
}

export { AnchorLink }
