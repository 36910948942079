import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMeasure } from 'react-use'
import { motion } from 'framer-motion'
import { Container } from '../../components/Container'
import { Img } from '../../components/Img'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import styles from './ImageHeader.styles.scss'
import animations from './ImageHeader.animations'

const ImageHeader = ({ heading, id, image, isScrollEnabled }) => {
  const [headingRef, headingSize] = useMeasure()
  const headingStyle = headingSize.height
    ? { marginTop: -headingSize.height }
    : {}
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el} id={id} width="full-bleed">
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.div
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
        >
          <div className={styles.el__image}>
            <Img {...image} objectFit="cover" fillContainer />
          </div>
          <Container>
            {heading && (
              <h2
                className={styles.el__heading}
                style={headingStyle}
                ref={headingRef}
                variants={animations.heading}
              >
                <motion.div
                  className={styles.el__heading__bg}
                  variants={animations.headingBg}
                />
                <motion.div
                  className={styles.el__heading__text}
                  variants={animations.headingText}
                >
                  {heading}
                </motion.div>
              </h2>
            )}
          </Container>
        </motion.div>
      </SectionWaypoint>
    </Section>
  )
}

ImageHeader.defaultProps = {
  heading: undefined,
  id: undefined,
  image: undefined,
  isScrollEnabled: true,
}

ImageHeader.propTypes = {
  heading: PropTypes.node,
  id: PropTypes.string,
  image: PropTypes.object,
  isScrollEnabled: PropTypes.bool,
}

export { ImageHeader }
