const ease = [0.165, 0.84, 0.44, 1]

const animations = {
  headingBg: {
    hidden: {
      scaleY: 0.33,
    },
    visible: {
      scaleY: 1,
      transition: {
        delay: 0,
        ease,
        duration: 1,
      },
    },
  },
  headingText: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 1,
      },
    },
  },
}

export default animations
