import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { motion, useViewportScroll } from 'framer-motion'
import jump from 'jump.js'
import { useActiveScrollID } from '../../hooks/useActiveScrollID'
import { AnchorLink } from '../AnchorLink'
import styles from './TableOfContents.styles.scss'
import ToggleSvg from './images/toggle.inline.svg'

const IS_BROWSER = typeof window !== 'undefined'

const TableOfContents = ({ items, ids }) => {
  const activeID = useActiveScrollID(ids)
  const activeItem = items.find((x) => x.id === activeID)
  const activeTitle = activeItem ? activeItem.title : ''
  const [isExpanded, setIsExpanded] = useState(false)
  const isMobile = useMedia({ maxWidth: '768px' })

  const handleToggleClick = () => setIsExpanded((prevState) => !prevState)
  const handleClickLink = () => setIsExpanded(false)

  useEffect(() => {
    if (IS_BROWSER) {
      const targetHash = window.location.hash
      if (targetHash) {
        const targetElement = document.querySelector(targetHash)
        if (
          targetElement &&
          targetElement.getBoundingClientRect().top > window.innerHeight
        ) {
          jump(targetHash, { duration: 1000, offset: -50 })
        }
      }
    }
  }, [])

  // framer.com/api/motion/examples/#viewport-scroll
  const { scrollYProgress } = useViewportScroll()

  return (
    <div className={styles.el}>
      {isMobile && (
        <button
          type="button"
          className={styles.el__toggle}
          onClick={handleToggleClick}
          data-active={isExpanded}
        >
          <span className={styles.el__toggle__wrap}>
            <span className={styles.el__toggle__text}>
              {activeTitle || 'Table of Contents'}
            </span>
            <span className={styles.el__toggle__icon}>
              <ToggleSvg />
            </span>
          </span>
        </button>
      )}
      <div className={styles.el__main} data-hidden={!isExpanded && isMobile}>
        <div className={styles.el__inner}>
          <div className={styles.el__progress}>
            <motion.div
              className={styles.el__progress__bar}
              style={{ scaleY: scrollYProgress }}
            />
          </div>
          <ul className={styles.el__list}>
            {items.map((item) => {
              return (
                <li
                  key={`toc-${item.id}`}
                  className={styles.el__list__item}
                  data-active={item.id === activeID}
                  data-indent={item.indent}
                >
                  <AnchorLink
                    href={`#${item.id}`}
                    offset={isMobile ? () => 60 : () => 0}
                    onClick={handleClickLink}
                    className={styles.el__link}
                    delay={isMobile ? 500 : 0}
                  >
                    {item.title}
                  </AnchorLink>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

TableOfContents.defaultProps = {
  items: [],
  ids: [],
}

TableOfContents.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  ids: PropTypes.array,
}

export { TableOfContents }
