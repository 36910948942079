import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import { Img } from '../../components/Img'
import styles from './IconHeader.styles.scss'
import animations from './IconHeader.animations'

const IconHeader = ({ heading, id, icon, isScrollEnabled }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el} id={id}>
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.div
          className={styles.el__wrap}
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
        >
          {icon && icon.src && (
            <motion.div className={styles.el__icon} variants={animations.icon}>
              <div className={styles.el__icon__inner}>
                <div className={styles.el__icon__sizer} />
                <Img
                  {...icon}
                  fillContainer
                  objectFit="contain"
                  objectPosition="center"
                />
              </div>
            </motion.div>
          )}
          {heading && (
            <motion.h2
              className={styles.el__heading}
              variants={animations.heading}
            >
              {heading}
            </motion.h2>
          )}
        </motion.div>
      </SectionWaypoint>
    </Section>
  )
}

IconHeader.defaultProps = {
  heading: undefined,
  icon: undefined,
  id: undefined,
  isScrollEnabled: true,
}

IconHeader.propTypes = {
  heading: PropTypes.node,
  icon: PropTypes.object,
  id: PropTypes.string,
  isScrollEnabled: PropTypes.bool,
}

export { IconHeader }
