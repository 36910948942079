import React from 'react'
import PropTypes from 'prop-types'
import { Section } from '../../components/Section'
import { Img } from '../../components/Img'
import { Link } from '../../components/Link'
import styles from './Logos.styles.scss'

const Logos = ({ items }) => {
  return (
    <Section className={styles.el}>
      <div className={styles.el__inner}>
        <ul className={styles.el__list}>
          {items.map((item) => {
            return (
              <li key={item.id} className={styles.el__item}>
                <Link to={item.link.url} target={item.link.target}>
                  <Img
                    {...item.image}
                    fillContainer
                    objectFit="contain"
                    objectPosition="center"
                  />
                  <div className={styles.el__item__spacer} />
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </Section>
  )
}

Logos.defaultProps = {
  items: [],
}

Logos.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      link: PropTypes.object,
    })
  ),
}

export { Logos }
