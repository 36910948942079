import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import { RichText } from '../../components/RichText'
import styles from './Expandable.styles.scss'
import animations from './Expandable.animations'

import LightbulbSvg from './images/lightbulb.inline.svg'
import CogSvg from './images/cog.inline.svg'
import CollapseSvg from './images/collapse.inline.svg'
import ExpandSvg from './images/expand.inline.svg'

const icons = {
  'light-bulb': <LightbulbSvg />,
  cog: <CogSvg />,
}

const Expandable = ({
  color,
  heading,
  icon,
  isScrollEnabled,
  isToggleable,
  prev,
  blocks,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const handleClickToggle = () => setIsExpanded((prevState) => !prevState)
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el} width="default" prev={prev}>
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.div
          className={styles.el__inner}
          data-color={color}
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
        >
          <div className={styles.el__container}>
            {isToggleable && (
              <button
                type="button"
                onClick={handleClickToggle}
                className={styles.el__head}
              >
                {icons[icon] && (
                  <motion.div
                    className={styles.el__icon}
                    variants={animations.icon}
                  >
                    {icons[icon]}
                  </motion.div>
                )}
                <motion.div
                  className={styles.el__head__text}
                  variants={animations.headText}
                >
                  {heading || 'More Info'}
                </motion.div>
                <motion.div
                  className={styles.el__toggle}
                  variants={animations.toggle}
                >
                  {isExpanded ? <CollapseSvg /> : <ExpandSvg />}
                </motion.div>
              </button>
            )}
            {!isToggleable && heading && (
              <div className={styles.el__head}>
                {icon && <div className={styles.el__icon}>{icons[icon]}</div>}
                {heading}
              </div>
            )}
            {(!isToggleable || isExpanded) && (
              <div className={styles.el__body}>
                {blocks.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.el__block}
                      data-style={item.style}
                    >
                      {item.heading && (
                        <h4 className={styles.el__block__heading}>
                          {item.heading}
                        </h4>
                      )}
                      <div className={styles.el__block__bodyText}>
                        <RichText>{item.bodyText}</RichText>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </motion.div>
      </SectionWaypoint>
    </Section>
  )
}

Expandable.defaultProps = {
  color: 'default',
  heading: undefined,
  icon: undefined,
  isScrollEnabled: true,
  isToggleable: true,
  prev: undefined,
  blocks: [],
}

Expandable.propTypes = {
  color: PropTypes.oneOf(['default', 'ochre', 'teal']),
  heading: PropTypes.node,
  icon: PropTypes.oneOf(['none', 'light-bulb', 'cog']),
  isScrollEnabled: PropTypes.bool,
  isToggleable: PropTypes.bool,
  prev: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      heading: PropTypes.node,
      bodyText: PropTypes.node,
      style: PropTypes.oneOf(['default', 'priority']),
    })
  ),
}

export { Expandable }
