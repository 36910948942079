import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import { RichText } from '../../components/RichText'
import styles from './Footnote.styles.scss'
import animations from './Footnote.animations'

const Footnote = ({ children, isScrollEnabled }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el}>
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.div
          className={styles.el__container}
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
          variants={animations.main}
        >
          <RichText>{children}</RichText>
        </motion.div>
      </SectionWaypoint>
    </Section>
  )
}

Footnote.defaultProps = {
  children: undefined,
  isScrollEnabled: true,
}

Footnote.propTypes = {
  children: PropTypes.node,
  isScrollEnabled: PropTypes.bool,
}

export { Footnote }
