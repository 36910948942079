import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Section.styles.scss'
import styleVariables from '../../styles/resources/all.scss'

const IS_BROWSER = typeof document !== 'undefined'

const Section = ({ children, className, id, next, prev, width }) => {
  const classes = classNames(styles.el, className)
  const [style, setStyle] = useState({})
  const maxPageWidth = parseInt(styleVariables.maxPageWidth, 10)

  useEffect(() => {
    const handleResize = () => {
      if (width === 'full-bleed' && IS_BROWSER) {
        const scrollDiv = document.createElement('div')
        scrollDiv.style.width = '100%'
        document.body.appendChild(scrollDiv)

        // Get the scrollbar width
        const windowWidth = scrollDiv.clientWidth // scrollDiv.offsetWidth - scrollDiv.clientWidth

        // Delete the div
        document.body.removeChild(scrollDiv)

        setStyle({
          width: `${Math.min(windowWidth, maxPageWidth)}px`,
          left: '50%',
          marginLeft: `-${Math.min(windowWidth, maxPageWidth) / 2}px`,
        })
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <section
      className={classes}
      data-width={width}
      style={style}
      data-next={next}
      data-prev={prev}
    >
      <div id={id} className={styles.el__marker} />
      {children}
    </section>
  )
}

Section.defaultProps = {
  children: undefined,
  className: undefined,
  id: undefined,
  width: 'default',
  prev: undefined,
  next: undefined,
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  width: PropTypes.string,
  prev: PropTypes.string,
  next: PropTypes.string,
}

export { Section }
