import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import { RichText } from '../../components/RichText'
import QuoteSvg from './images/quote.inline.svg'
import styles from './Text.styles.scss'
import animations from './Text.animations'

const Text = ({ children, isScrollEnabled, quote, quoteSource }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el}>
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.div
          className={styles.el__wrap}
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
        >
          <motion.div className={styles.el__main} variants={animations.main}>
            {children && <RichText>{children}</RichText>}
          </motion.div>
          <div className={styles.el__side}>
            {quote && (
              <blockquote className={styles.el__quote}>
                <motion.div
                  className={styles.el__quote__icon}
                  variants={animations.quoteIcon}
                >
                  <QuoteSvg />
                </motion.div>
                <motion.div
                  className={styles.el__quote__content}
                  variants={animations.quoteText}
                >
                  <RichText>{quote}</RichText>
                </motion.div>
                {quoteSource && (
                  <motion.footer
                    className={styles.el__quote__source}
                    variants={animations.quoteSource}
                  >
                    <RichText>{quoteSource}</RichText>
                  </motion.footer>
                )}
                <motion.div
                  className={styles.el__quote__line}
                  variants={animations.quoteLine}
                />
              </blockquote>
            )}
          </div>
        </motion.div>
      </SectionWaypoint>
    </Section>
  )
}

Text.defaultProps = {
  children: undefined,
  quote: undefined,
  quoteSource: undefined,
  isScrollEnabled: true,
}

Text.propTypes = {
  children: PropTypes.node,
  quote: PropTypes.node,
  quoteSource: PropTypes.node,
  isScrollEnabled: PropTypes.bool,
}

export { Text }
