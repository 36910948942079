import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Img } from '../../components/Img'
import { Section } from '../../components/Section'
import { BtnOverlay } from '../../components/BtnOverlay'
import { EnlargedLightbox } from '../../components/EnlargedLightbox'
import styles from './Image.styles.scss'

const Image = ({ image, enlargedImage, isEnlargeable, prev }) => {
  const [isEnlarged, setIsEnlarged] = useState(false)
  const handleEnlarge = () => setIsEnlarged(true)
  const handleCloseEnlarge = () => setIsEnlarged(false)

  return (
    <Section className={styles.el} prev={prev}>
      <div className={styles.el__container}>
        <div className={styles.el__image}>
          {isEnlargeable && enlargedImage && (
            <BtnOverlay
              onClick={handleEnlarge}
              icon="enlarge"
              position="bottom-right"
              fillContainer
            >
              Enlarge
            </BtnOverlay>
          )}
          <Img {...image} />
        </div>
      </div>
      {isEnlargeable && enlargedImage && (
        <EnlargedLightbox
          isActive={isEnlarged}
          onClose={handleCloseEnlarge}
          src={enlargedImage.url}
          maxHeight={`${enlargedImage.height}px`}
          maxWidth={`${enlargedImage.width}px`}
        />
      )}
    </Section>
  )
}

Image.defaultProps = {
  image: undefined,
  enlargedImage: undefined,
  isEnlargeable: false,
  prev: undefined,
}

Image.propTypes = {
  image: PropTypes.object,
  enlargedImage: PropTypes.object,
  isEnlargeable: PropTypes.bool,
  prev: PropTypes.string,
}

export { Image }
