const ease = [0.165, 0.84, 0.44, 1]

const animations = {
  line: {
    hidden: {
      scaleY: 0,
    },
    visible: (delay) => ({
      scaleY: 1,
      transition: {
        ease,
        duration: 1,
        delay,
      },
    }),
  },
  content: {
    hidden: {
      opacity: 0,
    },
    visible: (delay) => ({
      opacity: 1,
      transition: {
        delay,
      },
    }),
  },
}

export default animations
