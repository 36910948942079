import { useEffect, useState } from 'react'
// https://nickymeuleman.netlify.app/blog/table-of-contents

const IS_BROWSER = typeof document !== 'undefined'

export const useActiveScrollID = (itemIds) => {
  const [activeId, setActiveId] = useState(undefined)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (IS_BROWSER && 'replaceState' in window.history) {
              window.history.replaceState(null, null, `#${entry.target.id}`)
            }
            setActiveId(entry.target.id)
          }
        })
      },
      { rootMargin: `0% 0% -80% 0%` }
    )
    itemIds.forEach((id) => {
      const element = document.getElementById(id)
      if (element) {
        observer.observe(element)
      }
    })
    return () => {
      if (IS_BROWSER && 'replaceState' in window.history) {
        window.history.replaceState(null, null, ` `)
      }
      itemIds.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.unobserve(element)
        }
      })
    }
  }, [itemIds])
  return activeId
}
