import React, { useState } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { Img } from '../Img'
import { BtnPlay } from '../BtnPlay'
import styles from './VideoPlayer.styles.scss'

const VideoPlayer = ({
  aspectRatio,
  controls,
  onEnded,
  onPause,
  posterImg,
  url,
}) => {
  const spacer = aspectRatio ? `${aspectRatio * 100}%` : undefined
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlayVideo = () => {
    setIsPlaying(true)
  }

  const handleOnEnd = () => {
    setIsPlaying(false)

    if (typeof onEnded === 'function') {
      onEnded()
    }
  }

  return (
    <div className={styles.el}>
      {spacer && <div style={{ paddingTop: spacer }} />}
      {posterImg && (
        <motion.button
          type="button"
          className={styles.el__poster}
          aria-label="Play Video"
          onClick={handlePlayVideo}
          initial={{ opacity: 0 }}
          animate={{ opacity: isPlaying ? 0 : 1 }}
          style={{ pointerEvents: isPlaying ? 'none' : 'auto' }}
        >
          <div className={styles.el__poster__inner}>
            {posterImg && (
              <Img {...posterImg} objectFit="cover" fillContainer />
            )}
            <div className={styles.el__poster__play}>
              <BtnPlay />
            </div>
          </div>
        </motion.button>
      )}
      <ReactPlayer
        className={styles.el__video}
        onEnded={handleOnEnd}
        onPause={onPause}
        url={url}
        playing={isPlaying}
        muted={false}
        width="100%"
        height="auto"
        controls={controls}
        playsinline
      />
    </div>
  )
}

VideoPlayer.defaultProps = {
  aspectRatio: 9 / 16,
  controls: true,
  onEnded: () => {},
  onPause: () => {},
  posterImg: undefined,
  url: undefined,
}

VideoPlayer.propTypes = {
  aspectRatio: PropTypes.number,
  controls: PropTypes.bool,
  onEnded: PropTypes.func,
  onPause: PropTypes.func,
  posterImg: PropTypes.object,
  url: PropTypes.string,
}

export { VideoPlayer }
