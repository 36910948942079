import React from 'react'
import PropTypes from 'prop-types'
import styles from './BtnOverlay.styles.scss'

import EnlargeSvg from './images/enlarge.inline.svg'
import CloseSvg from './images/close.inline.svg'
import ZoomInSvg from './images/zoom-in.inline.svg'
import ZoomOutSvg from './images/zoom-out.inline.svg'

const ICONS = {
  enlarge: <EnlargeSvg />,
  close: <CloseSvg />,
  'zoom-in': <ZoomInSvg />,
  'zoom-out': <ZoomOutSvg />,
}

const BtnOverlay = ({ children, icon, onClick, position, fillContainer }) => {
  return (
    <button
      type="button"
      className={styles.el}
      onClick={onClick}
      aria-label="Enlarge"
      data-position={position}
      data-fill-container={fillContainer}
    >
      <span className={styles.el__inner}>
        <span className={styles.el__wrap}>
          {children && <span className={styles.el__text}>{children}</span>}
          {icon && <span className={styles.el__icon}>{ICONS[icon]}</span>}
        </span>
      </span>
    </button>
  )
}

BtnOverlay.defaultProps = {
  fillContainer: false,
  icon: undefined,
  onClick: () => {},
  position: 'inline',
}

BtnOverlay.propTypes = {
  fillContainer: PropTypes.bool,
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOf(['enlarge', 'close', 'zoom-in', 'zoom-out']),
  onClick: PropTypes.func,
  position: PropTypes.oneOf(['inline', 'bottom-right', 'top-right']),
}

export { BtnOverlay }
