const ease = [0.165, 0.84, 0.44, 1]

const animations = {
  line: {
    hidden: {
      scaleY: 0,
    },
    visible: {
      scaleY: 1,
      transition: {
        ease,
        duration: 1,
        delay: 0.5,
      },
    },
  },
  quote: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
}

export default animations
