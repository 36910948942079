/**
 * convert an aspect ratio name to a decimal
 *
 * @param {string} aspectRatio
 */

const ASPECT_RATIOS = {
  '16:9': 9 / 16,
  '4:3': 3 / 4,
  '16:10': 10 / 16,
  '2:1': 1 / 2,
  '1:1': 1,
  Square: 1,
  Auto: undefined,
}

export const getAspectRatio = (aspectRatio) => {
  return ASPECT_RATIOS[aspectRatio]
}
