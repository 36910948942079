import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import styles from './TextHeader.styles.scss'
import animations from './TextHeader.animations'

const TextHeader = ({ heading, id, isScrollEnabled }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el} id={id} width="full-bleed">
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.div
          className={styles.el__container}
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
        >
          <Container>
            <div className={styles.el__inner}>
              <div className={styles.el__inner__content}>
                <div className={styles.el__bg} />
                {heading && (
                  <motion.h2
                    className={styles.el__heading}
                    variants={animations.heading}
                  >
                    {heading}
                  </motion.h2>
                )}
              </div>
            </div>
          </Container>
        </motion.div>
      </SectionWaypoint>
    </Section>
  )
}

TextHeader.defaultProps = {
  heading: undefined,
  id: undefined,
  isScrollEnabled: true,
}

TextHeader.propTypes = {
  heading: PropTypes.node,
  id: PropTypes.string,
  isScrollEnabled: PropTypes.bool,
}

export { TextHeader }
