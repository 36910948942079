import { getRichText } from './getRichText'
import { getAspectRatio } from './getAspectRatio'
import { getLink } from './getLink'
import { getSlug } from '../../utils/getSlug'
// import { get } from '../../utils/get'
import { getImage } from './getImage'
import { get } from '../../utils/get'

/**
 * Process prismic slice data that can be passed to components as props
 *
 * @param {array} data
 * @param {object} location
 */

export const sectionSerializer = (data, location = {}) => {
  const sections = []

  data.forEach((slice, sliceIndex) => {
    if (slice) {
      // section object
      const section = {
        type: slice.slice_type,
        id: slice.id,
      }

      section.location = location
      section.id = `section-${sliceIndex}`
      section.isScrollEnabled = sliceIndex !== 0

      // restructure 'primary' object prop to root
      if (slice.primary) {
        Object.keys(slice.primary).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(slice.primary, key)) {
            slice[key] = slice.primary[key]
          }
        })
        delete slice.primary
      }

      // contributor
      if (slice.slice_type === 'contributor') {
        section.name = getRichText(slice, 'name.raw', 'p')
        section.description = getRichText(slice, 'description.raw')
        section.image = getImage(slice, 'image', 'fixed')
      }

      // diagram
      else if (slice.slice_type === 'diagram') {
        section.image = getImage(slice, 'image', 'fluid')
        section.isEnlargeable = slice.enlargeable
      }

      // expandable
      else if (slice.slice_type === 'expandable') {
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.color = getSlug(slice.color)
        section.icon = getSlug(slice.icon)
        section.isToggleable = slice.toggleable

        section.blocks = []
        slice.items.forEach((item, index) => {
          section.blocks.push({
            id: `expandable-${sliceIndex}-${index}`,
            heading: getRichText(item, 'heading.raw', 'p'),
            bodyText: getRichText(item, 'body_text.raw'),

            style: getSlug(item.style) || 'default',
          })
        })
      }

      // footnote
      else if (slice.slice_type === 'footnote') {
        section.children = getRichText(slice, 'content.raw')
      }

      // header icon
      else if (slice.slice_type === 'header_icon') {
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.icon = getImage(slice, 'image', 'fluid')

        if (get(slice, 'table_of_contents_title')) {
          const id = getSlug(slice.table_of_contents_title)
          section.id = id
          section.tableOfContents = {
            id,
            title: get(slice, 'table_of_contents_title'),
            indent: 2,
          }
        }
      }

      // header image
      else if (slice.slice_type === 'header_image') {
        section.heading = getRichText(slice, 'heading.raw', 'p')
        section.image = getImage(slice, 'image', 'fluid')

        if (get(slice, 'table_of_contents_title')) {
          const id = getSlug(slice.table_of_contents_title)
          section.id = id
          section.tableOfContents = {
            id,
            title: get(slice, 'table_of_contents_title'),
            indent: 0,
          }
        }
      }

      // header text
      else if (slice.slice_type === 'header_text') {
        section.heading = getRichText(slice, 'heading.raw', 'p')

        if (get(slice, 'table_of_contents_title')) {
          const id = getSlug(slice.table_of_contents_title)
          section.id = id
          section.tableOfContents = {
            id,
            title: get(slice, 'table_of_contents_title'),
            indent: 1,
          }
        }
      }

      // image
      else if (slice.slice_type === 'image') {
        section.image = getImage(slice, 'image', 'fluid')
        section.isEnlargeable = slice.enlargeable
        section.enlargedImage = get(slice, 'enlarged_image.localFile.url')
          ? {
              url: slice.enlarged_image.localFile.url,
              width: slice.enlarged_image.dimensions.width,
              height: slice.enlarged_image.dimensions.height,
            }
          : undefined
      }

      // list
      else if (slice.slice_type === 'list') {
        section.items = []
        slice.items.forEach((item, index) => {
          section.items.push({
            id: `list-${sliceIndex}-${index}`,
            numbering: getSlug(item.numbering) || 'auto',
            heading: getRichText(item, 'heading.raw', 'p'),
            content: getRichText(item, 'content.raw'),
          })
        })
      }

      // logos
      else if (slice.slice_type === 'logos') {
        section.items = []
        slice.items.forEach((item, index) => {
          section.items.push({
            id: `list-${sliceIndex}-${index}`,
            title: item.title,
            image: getImage(item, 'image', 'fluid'),
            link: getLink(item.link),
          })
        })
      }

      // quote
      else if (slice.slice_type === 'quote') {
        section.children = getRichText(slice, 'content.raw')
      }

      // video
      else if (slice.slice_type === 'video') {
        section.poster = getImage(slice, 'poster', 'fluid')
        section.url = slice.url
        section.aspectRatio = getAspectRatio(slice.aspect_ratio)
        section.caption = getRichText(slice, 'caption.raw')
      }

      // text
      else if (slice.slice_type === 'text') {
        section.children = getRichText(slice, 'content.raw')
        section.quote = getRichText(slice, 'quote.raw')
        section.quoteSource = getRichText(slice, 'quote_source.raw')
      }

      // add to output array
      sections.push(section)
    }
  })

  return sections
}
