import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import { pageTransitionDuration } from '../PageTransition'

const readyDelay = (pageTransitionDuration + 0.25) * 1000

const SectionWaypoint = ({ children, onEnter, onLeave, isScrollEnabled }) => {
  const handleOnEnter = () => onEnter()
  const handleOnLeave = () => onLeave()

  // manually trigger if not scroll enabled
  useEffect(() => {
    setTimeout(() => {
      if (!isScrollEnabled) {
        handleOnEnter()
      }
    }, readyDelay)
  }, [])

  if (isScrollEnabled) {
    return (
      <Waypoint onEnter={handleOnEnter} onLeave={handleOnLeave}>
        <div>{children}</div>
      </Waypoint>
    )
  }

  return children
}

SectionWaypoint.defaultProps = {
  onEnter: () => {},
  onLeave: () => {},
  isScrollEnabled: true,
}

SectionWaypoint.propTypes = {
  children: PropTypes.node.isRequired,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  isScrollEnabled: PropTypes.bool,
}

export { SectionWaypoint }
