import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { BtnOverlay } from '../BtnOverlay'
import styles from './EnlargedLightbox.styles.scss'

const IS_BROWSER = typeof window !== 'undefined'

const EnlargedLightbox = ({ isActive, maxHeight, maxWidth, onClose, src }) => {
  const ref = useRef()
  const handleClickCloseZoom = () => onClose()

  useEffect(() => {
    if (IS_BROWSER) {
      if (isActive) {
        disableBodyScroll(ref.current, { reserveScrollBarGap: true })
      } else {
        enableBodyScroll(ref.current)
      }
    }
  }, [isActive])

  if (IS_BROWSER) {
    return createPortal(
      <div className={styles.el} ref={ref} data-active={isActive}>
        <BtnOverlay
          onClick={handleClickCloseZoom}
          className={styles.el__close}
          fillContainer={false}
          icon="close"
          position="top-right"
        >
          Close
        </BtnOverlay>
        {isActive && (
          <TransformWrapper>
            {({ zoomIn, zoomOut }) => (
              <>
                <div className={styles.el__btns}>
                  <BtnOverlay onClick={zoomIn} icon="zoom-in">
                    Zoom In
                  </BtnOverlay>
                  <BtnOverlay onClick={zoomOut} icon="zoom-out">
                    Zoom Out
                  </BtnOverlay>
                </div>
                <TransformComponent>
                  <div className={styles.el__inner}>
                    <img
                      src={src}
                      alt="Zoomed"
                      className={styles.el__img}
                      style={{ maxWidth, maxHeight }}
                    />
                  </div>
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        )}
      </div>,
      document.body
    )
  }

  return null
}

EnlargedLightbox.defaultProps = {
  isActive: false,
  maxWidth: '1500px',
  maxHeight: '1500px',
  onClose: () => {},
  src: undefined,
}

EnlargedLightbox.propTypes = {
  isActive: PropTypes.bool,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  onClose: PropTypes.func,
  src: PropTypes.string,
}

export { EnlargedLightbox }
