const animations = {
  icon: {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        delay: 0.5,
        duration: 0.5,
      },
    },
  },
  headText: {
    hidden: {
      x: -40,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 0.5,
      },
    },
  },
  toggle: {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        delay: 1,
        duration: 0.5,
      },
    },
  },
}

export default animations
