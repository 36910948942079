const animations = {
  main: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transtion: { delay: 0.5 },
    },
  },
}

export default animations
