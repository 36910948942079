import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import { RichText } from '../../components/RichText'
import styles from './Quote.styles.scss'
import animations from './Quote.animations'

const Quote = ({ children, isScrollEnabled }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el}>
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.div
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
          className={styles.el__inner}
        >
          <motion.div className={styles.el__line} variants={animations.line} />
          <motion.blockquote
            className={styles.el__quote}
            variants={animations.quote}
          >
            {children && <RichText>{children}</RichText>}
          </motion.blockquote>
        </motion.div>
      </SectionWaypoint>
    </Section>
  )
}

Quote.defaultProps = {
  children: undefined,
  isScrollEnabled: true,
}

Quote.propTypes = {
  children: PropTypes.node,
  isScrollEnabled: PropTypes.bool,
}

export { Quote }
