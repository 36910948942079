import React from 'react'
import PropTypes from 'prop-types'
import { Section } from '../../components/Section'
import { VideoPlayer } from '../../components/VideoPlayer'
import { RichText } from '../../components/RichText'
import styles from './Video.styles.scss'

const Video = ({ caption, url, aspectRatio, poster }) => {
  return (
    <Section className={styles.el}>
      <div className={styles.el__container}>
        <VideoPlayer url={url} aspectRatio={aspectRatio} posterImg={poster} />
        {caption && (
          <div className={styles.el__caption}>
            <RichText>{caption}</RichText>
          </div>
        )}
      </div>
    </Section>
  )
}

Video.defaultProps = {
  caption: undefined,
  aspectRatio: 9 / 16,
  url: undefined,
  poster: undefined,
}

Video.propTypes = {
  caption: PropTypes.node,
  aspectRatio: PropTypes.number,
  url: PropTypes.string,
  poster: PropTypes.object,
}

export { Video }
