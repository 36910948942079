const ease = [0.165, 0.84, 0.44, 1]

const animations = {
  image: {
    hidden: {
      scale: 0.75,
      opacity: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 1,
        delay: 0.5,
        ease,
      },
    },
  },
  text: {
    hidden: {
      x: -100,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        delay: 0.75,
        ease,
      },
    },
  },
}

export default animations
