import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import { Img } from '../../components/Img'
import { RichText } from '../../components/RichText'
import styles from './Contributor.styles.scss'
import animations from './Contributor.animations'

const Contributor = ({ name, image, description, isScrollEnabled }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el}>
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.div
          className={styles.el__inner}
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
        >
          {image && (
            <motion.div
              className={styles.el__image}
              variants={animations.image}
            >
              <Img {...image} fillContainer objectFit="cover" />
            </motion.div>
          )}
          <motion.div className={styles.el__text} variants={animations.text}>
            <div className={styles.el__name}>{name}</div>
            <div className={styles.el__description}>
              <RichText>{description}</RichText>
            </div>
          </motion.div>
        </motion.div>
      </SectionWaypoint>
    </Section>
  )
}

Contributor.defaultProps = {
  name: undefined,
  image: undefined,
  description: undefined,
  isScrollEnabled: true,
}

Contributor.propTypes = {
  name: PropTypes.node,
  image: PropTypes.object,
  description: PropTypes.node,
  isScrollEnabled: PropTypes.bool,
}

export { Contributor }
