import React from 'react'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import { Container } from '../../components/Container'
import { TableOfContents } from '../../components/TableOfContents'
import { SectionRouter } from '../../components/SectionRouter'
import { Btn } from '../../components/Btn'
import { ImageHeader } from '../../sections/ImageHeader'
import { IconHeader } from '../../sections/IconHeader'
import { TextHeader } from '../../sections/TextHeader'
import { Contributor } from '../../sections/Contributor'
import { Diagram } from '../../sections/Diagram'
import { Footnote } from '../../sections/Footnote'
import { Expandable } from '../../sections/Expandable'
import { Hero } from '../../sections/Hero'
import { Image } from '../../sections/Image'
import { List } from '../../sections/List'
import { Logos } from '../../sections/Logos'
import { Quote } from '../../sections/Quote'
import { Text } from '../../sections/Text'
import { Video } from '../../sections/Video'
import styles from './Page.styles.scss'

const Page = ({
  download,
  heroHeading,
  heroImage,
  sections,
  sectionIds,
  tableOfContents,
  tableOfContentsEnabled,
}) => {
  const handleClickDownload = (url, filename) => saveAs(url, filename)

  return (
    <div className={styles.el}>
      <Hero image={heroImage} id="mihi-welcome" isScrollEnabled={false}>
        {heroHeading}
      </Hero>
      <div className={styles.el__inner}>
        <div className={styles.el__fixed}>
          <Container>
            <div className={styles.el__fixed__wrap}>
              <div className={styles.el__fixed__primary}>
                {tableOfContentsEnabled && (
                  <div className={styles.el__tableOfContents}>
                    <TableOfContents items={tableOfContents} ids={sectionIds} />
                  </div>
                )}
              </div>
              <div className={styles.el__fixed__secondary}>
                {download.url && (
                  <div className={styles.el__download}>
                    <Btn
                      icon="download"
                      onClick={() =>
                        handleClickDownload(download.url, download.name)
                      }
                    >
                      {download.buttonLabel}
                    </Btn>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <div className={styles.el__main}>
            <SectionRouter
              sections={sections}
              types={{
                contributor: Contributor,
                diagram: Diagram,
                expandable: Expandable,
                footnote: Footnote,
                header_icon: IconHeader,
                header_image: ImageHeader,
                header_text: TextHeader,
                image: Image,
                list: List,
                logos: Logos,
                quote: Quote,
                text: Text,
                video: Video,
              }}
            />
          </div>
        </Container>
      </div>
    </div>
  )
}

Page.defaultProps = {
  download: {},
  heroHeading: undefined,
  heroImage: undefined,
  sections: [],
  sectionIds: [],
  tableOfContents: [],
  tableOfContentsEnabled: false,
}

Page.propTypes = {
  download: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
    buttonLabel: PropTypes.string,
  }),
  heroHeading: PropTypes.node,
  heroImage: PropTypes.object,
  sections: PropTypes.array,
  sectionIds: PropTypes.array,
  tableOfContents: PropTypes.array,
  tableOfContentsEnabled: PropTypes.bool,
}

export default Page
