import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { get } from '../../utils/get'
import { getSlug } from '../../utils/getSlug'
import { sectionSerializer } from '../utils/sectionSerializer'
import { getSiteConfig } from '../utils/getSiteConfig'
import { getRichText } from '../utils/getRichText'
import { getImage } from '../utils/getImage'
import PageTemplate from '../../templates/Page'

const PagePrismic = ({ data }) => {
  const site = getSiteConfig()
  const { prismicPage } = data
  const slices = get(prismicPage, 'data.body') || []
  const sections = sectionSerializer(slices)

  const tableOfContentsEnabled = get(
    prismicPage,
    'data.table_of_contents_enabled'
  )

  const tableOfContents = []
  const ids = []

  if (tableOfContentsEnabled) {
    if (get(prismicPage, 'data.heading.text')) {
      const id = getSlug(prismicPage.data.heading.text)
      tableOfContents.push({
        title: prismicPage.data.heading.text,
        id,
        indent: 0,
      })
      ids.push(id)
    }

    sections.forEach((section) => {
      if (section.tableOfContents) {
        tableOfContents.push(section.tableOfContents)
        ids.push(section.tableOfContents.id)
      }
    })
  }

  return (
    <PageTemplate
      heroHeading={getRichText(prismicPage, 'data.heading.raw')}
      heroImage={getImage(prismicPage, 'data.image', 'fluid')}
      sections={sections}
      sectionIds={ids}
      tableOfContents={tableOfContents}
      tableOfContentsEnabled={tableOfContentsEnabled}
      download={site.download}
    />
  )
}

PagePrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPreview(PagePrismic)

export const query = graphql`
  query PageByID($id: String!) {
    prismicPage(id: { eq: $id }) {
      uid
      id
      type
      data {
        title
        table_of_contents_enabled
        heading {
          raw
          text
        }
        image {
          fluid(maxWidth: 3000) {
            ...GatsbyPrismicImageFluid_noBase64
          }
          url
          alt
          dimensions {
            width
            height
          }
        }
        body {
          ... on PrismicPageBodyText {
            id
            slice_type
            primary {
              content {
                raw
              }
              quote_source {
                raw
              }
              quote {
                raw
              }
            }
          }
          ... on PrismicPageBodyQuote {
            id
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicPageBodyHeaderText {
            id
            slice_type
            primary {
              table_of_contents_title
              heading {
                raw
              }
            }
          }
          ... on PrismicPageBodyHeaderIcon {
            id
            slice_type
            primary {
              table_of_contents_title
              heading {
                raw
              }
              image {
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageBodyHeaderImage {
            id
            slice_type
            primary {
              table_of_contents_title
              heading {
                raw
              }
              image {
                fluid(maxWidth: 3000) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageBodyVideo {
            id
            slice_type
            primary {
              caption {
                raw
              }
              poster {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              aspect_ratio
              url
            }
          }
          ... on PrismicPageBodyList {
            id
            slice_type
            items {
              heading {
                raw
              }
              content {
                raw
              }
              numbering
            }
          }
          ... on PrismicPageBodyLogos {
            id
            slice_type
            items {
              image {
                fluid(maxWidth: 250) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              title
            }
          }
          ... on PrismicPageBodyImage {
            id
            slice_type
            primary {
              enlargeable
              enlarged_image: image {
                localFile {
                  url
                }
                dimensions {
                  width
                  height
                }
              }
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageBodyFootnote {
            id
            slice_type
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicPageBodyExpandable {
            id
            slice_type
            primary {
              heading {
                raw
              }
              color
              icon
              toggleable
            }
            items {
              body_text {
                raw
              }
              heading {
                raw
              }
              style
            }
          }
          ... on PrismicPageBodyDiagram {
            id
            slice_type
            primary {
              enlargeable
              image {
                fluid(maxWidth: 3000) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageBodyContributor {
            id
            slice_type
            primary {
              description {
                raw
              }
              image {
                fixed(width: 200, height: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              name {
                raw
              }
            }
          }
        }
      }
    }
  }
`
