import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import { RichText } from '../../components/RichText'
import styles from './List.styles.scss'
import animations from './List.animations'

const List = ({ items, isScrollEnabled }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el}>
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.ul
          className={styles.el__list}
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
        >
          {items.map((item, index) => {
            return (
              <li key={item.id} className={styles.el__item}>
                {item.heading && (
                  <div className={styles.el__heading}>
                    <motion.div
                      className={styles.el__heading__line}
                      variants={animations.line}
                      custom={index * 0.5}
                    />
                    {item.numbering !== 'none' && (
                      <div className={styles.el__heading__number}>
                        {item.numbering === 'auto' ? index + 1 : item.numbering}
                      </div>
                    )}
                    <h3 className={styles.el__heading__text}>{item.heading}</h3>
                  </div>
                )}
                <motion.div
                  className={styles.el__item__content}
                  variants={animations.content}
                  custom={index * 0.5 + 0.2}
                >
                  <RichText>{item.content}</RichText>
                </motion.div>
              </li>
            )
          })}
        </motion.ul>
      </SectionWaypoint>
    </Section>
  )
}

List.defaultProps = {
  items: [],
  isScrollEnabled: true,
}

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      heading: PropTypes.node,
      numbering: PropTypes.string,
      content: PropTypes.node,
    })
  ),
  isScrollEnabled: PropTypes.bool,
}

export { List }
