import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Container } from '../../components/Container'
import { Img } from '../../components/Img'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import styles from './Hero.styles.scss'
import animations from './Hero.animations'

const Hero = ({ children, id, image, isScrollEnabled }) => {
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el} width="full-bleed" id={id}>
      <SectionWaypoint onEnter={handleEnter} isScrollEnabled={isScrollEnabled}>
        <motion.div
          className={styles.el__image}
          initial="hidden"
          animate={isTransitioned ? 'visible' : 'hidden'}
        >
          <motion.div className={styles.el__bg} variants={animations.bg}>
            {image && <Img {...image} fillContainer objectFit="cover" />}
          </motion.div>
          <Container className={styles.el__container}>
            {children && (
              <motion.h2
                className={styles.el__heading}
                variants={animations.heading}
              >
                {children}
              </motion.h2>
            )}
          </Container>
        </motion.div>
      </SectionWaypoint>
    </Section>
  )
}

Hero.defaultProps = {
  children: undefined,
  id: undefined,
  image: undefined,
  isScrollEnabled: true,
}

Hero.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  image: PropTypes.object,
  isScrollEnabled: PropTypes.bool,
}

export { Hero }
