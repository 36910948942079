const ease = [0.165, 0.84, 0.44, 1]

const animations = {
  heading: {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease,
        delay: 0.5,
        duration: 1,
      },
    },
  },
  bg: {
    hidden: {
      y: 20,
    },
    visible: {
      y: 0,
      transition: {
        ease,
        delay: 0.5,
        duration: 1,
      },
    },
  },
}

export default animations
