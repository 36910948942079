import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Section } from '../../components/Section'
import { SectionWaypoint } from '../../components/SectionWaypoint'
import { Img } from '../../components/Img'
import { BtnOverlay } from '../../components/BtnOverlay'
import { EnlargedLightbox } from '../../components/EnlargedLightbox'
import styles from './Diagram.styles.scss'
import animations from './Diagram.animations'

const Diagram = ({ image, isEnlargeable, isScrollEnabled }) => {
  const [isEnlarged, setIsEnlarged] = useState(false)
  const handleEnlarge = () => setIsEnlarged(true)
  const handleCloseEnlarge = () => setIsEnlarged(false)
  const [isTransitioned, setIsTransitioned] = useState(false)
  const handleEnter = () => setIsTransitioned(true)

  return (
    <Section className={styles.el} width="default">
      {image && (
        <>
          <SectionWaypoint
            onEnter={handleEnter}
            isScrollEnabled={isScrollEnabled}
          >
            <motion.div
              className={styles.el__inner}
              initial="hidden"
              animate={isTransitioned ? 'visible' : 'hidden'}
            >
              {isEnlargeable && (
                <BtnOverlay
                  onClick={handleEnlarge}
                  icon="enlarge"
                  position="bottom-right"
                  fillContainer
                >
                  Enlarge
                </BtnOverlay>
              )}

              <Img {...image} />
              <motion.div
                className={styles.el__line}
                variants={animations.line}
              />
            </motion.div>
          </SectionWaypoint>
          <EnlargedLightbox
            isActive={isEnlarged}
            onClose={handleCloseEnlarge}
            src={image.src}
            maxHeight={`${image.height}px`}
            maxWidth={`${image.width}px`}
          />
        </>
      )}
    </Section>
  )
}

Diagram.defaultProps = {
  image: undefined,
  isEnlargeable: false,
  isScrollEnabled: true,
}

Diagram.propTypes = {
  image: PropTypes.object,
  isEnlargeable: PropTypes.bool,
  isScrollEnabled: PropTypes.bool,
}

export { Diagram }
